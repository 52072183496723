<template>
  <div class="navbar clearfix">
    <el-breadcrumb class="breadcrumb-container" separator="/">
      <!-- <el-breadcrumb-item
        v-for="item in levelList"
        :key="item.path"
        :to="item.path"
        >{{ item.meta.title }}</el-breadcrumb-item
      > -->

      <!-- <el-breadcrumb-item :to="{ path: levelList.path }">{{
        levelList.meta[0]
      }}</el-breadcrumb-item> -->
      <el-breadcrumb-item v-for="(item, i) in levelList.title" :key="i">{{
        item
      }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      levelList: [],
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      console.log(this.$route.matched);
      //$route.matched一个数组 包含当前路由的所有嵌套路径片段的路由记录
      if (this.$route.matched[1] && this.$route.matched[1].meta) {
        var matched = this.$route.matched[1].meta;
        this.levelList = matched;
      }
      // const first = matched[0];
      // if (
      //   first &&
      //   first.name.trim().toLocaleLowerCase() !==
      //     "Dashboard".toLocaleLowerCase()
      // ) {
      //   matched = [{ path: "/dashboard", meta: { title: "" } }].concat(matched);
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
.el-breadcrumb__item {
  &:last-child {
    /deep/.el-breadcrumb__inner {
      color: #323233;
      font-size: 14px;
    }
  }
}
</style>
